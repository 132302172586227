<template>
  <div class="page page-Article" :class="{ loaded: isLoaded }">
    <router-link :to="{ name: 'Rules' }" class="back link m300 z-1"
      >{{ $t('lang.menu.backButton') }}
    </router-link>

    <div class="half half-reverse half-article content">
      <div class="half__pic m25" :style="bgImgStyle(backgroundImages)"></div>
      <div class="half__text m50 article negative-z">
        <div v-if="doc" class="article__iframe-container">
          <div class="article__title m50">{{ title }}</div>
          <div class="article__iframe-sub-container">
            <iframe class="article__iframe" :src="doc" loading="lazy" />
          </div>
        </div>
        <VuePerfectScrollbar v-else class="scroll-area" :settings="psSettings">
          <div class="article__title m50">{{ title }}</div>
          <div
            class="article__text"
            :class="`article--${$route.params.id}`"
            v-html="text"
          ></div>
        </VuePerfectScrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { loader } from '../mixins/loader';
import { functions } from '../mixins/functions';

export default {
  name: 'Article',
  mixins: [loader, functions],
  props: ['id'],
  data() {
    return {
      pageData: { page: 'Article', isHeaderHidden: true },
      psSettings: {},
      backgroundImages: { '2x': '/img/06@2x.jpg' },
      title: '',
      text: '',
      doc: '',
    };
  },
  mounted() {
    this.$store.commit('ui/loaderON');
    this.onActivated();
  },
  methods: {
    onActivated() {
      if (this.imagesLoaded) {
        this.pageLoaded();
      } else {
        const images = [];

        for (const i in this.tabs) {
          this.tabs[i].background = this.bgImgStyle(
            this.tabs[i].backgroundImages,
          );

          for (const j in this.tabs[i].backgroundImages) {
            images.push(this.tabs[i].backgroundImages[j]);
          }
        }

        this.loadImages(images);
      }

      this.$store
        .dispatch('articles/loadArticle', {
          id: this.id,
        })
        .then(() => {
          this.title =
            this.$i18n.locale === 'ru'
              ? this.$store.state.articles.article.titleRu
              : this.$store.state.articles.article.title;
          this.text = this.$store.state.articles.article.text;
          this.doc = this.$store.state.articles.article.doc;
        });
    },
  },
};
</script>
<style lang="less">
/*надо чтобы текст не прилипал к ячейкам таблицы, однако contract приходит с бека, в его форматирование лезть не надо*/
.article__text:not(.article--contract) td {
  padding: 5px;
}

.article--contract p span {
  background-color: transparent;
}

.article--contract .P57 {
  background-color: transparent;
}

.article--contract .Table3,
.article--contract .Table5 {
  display: none;
}

.article__iframe-container {
  margin-top: 10vh;

  @media (max-width: 1400px) {
    margin-top: 2vh;
  }

  @media (max-width: 767px) {
    overflow: hidden;
    max-height: 67vh;
  }
}

.article__iframe-sub-container {
  position: relative;
  height: 75vh;
}

.article__iframe {
  position: absolute;
  border: none;
  width: 100%;
  height: 75vh;

  @media (max-width: 1400px) {
    transform: scale(0.6) translateX(-28vw) translateY(-40vh);
    width: 76vw;
    height: 130vh;
  }

  @media (max-width: 767px) {
    width: 160vw;
    transform: scale(0.6) translateX(-60vw) translateY(-40vh);
    height: 115vh;
    z-index: 20;
  }
}

.z-1 {
  z-index: 1;
}
</style>
